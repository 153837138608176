<template>
  <div style="height: inherit">
    <b-modal
        v-model="createSignalModal"
        id="modal-create-signal"
        cancel-variant="outline-secondary"
        ok-title="Создать"
        cancel-title="Закрыть"
        centered
        title="Создание сигнала"
        @ok="createNewSignal"
    >
      <b-form>
        <b-form-group>
          <div class="position-signal">
            <span class="long" :class="{ active: activeLong }" @click="changePosition('long')">Продать</span>
            <span class="short" :class="{ active: activeShort }" @click="changePosition('short')">Купить</span>
          </div>
        </b-form-group>
        <b-form-group>
          <label>Пара</label>
          <v-select
              v-model="newSignal.currencyPair"
              label="symbol"
              key="id"
              :options="currencyPairsSelect"
          />
        </b-form-group>
        <b-form-group>
          <label for="leverage">Плечо:</label>
          <b-form-input
              v-model="newSignal.adjustLeverage"
              id="leverage"
              type="number"
              placeholder="Плечо"
          />
        </b-form-group>
        <b-form-group>
          <label for="price">Покупка:</label>
          <b-form-input
              v-model="newSignal.startFrom"
              id="price"
              type="number"
              placeholder="Покупка"
          />
        </b-form-group>
        <b-form-group>
          <label for="sum">Take profit</label>
          <b-form-input
              v-model="newSignal.takeProfit1"
              id="sum"
              type="number"
              placeholder="Take profit"
          />
        </b-form-group>
        <b-form-group>
          <label for="sum">Take profit 2</label>
          <b-form-input
              v-model="newSignal.takeProfit2"
              id="sum"
              type="number"
              placeholder="Take profit 2"
          />
        </b-form-group>
        <b-form-group>
          <label for="sum">Take profit 3</label>
          <b-form-input
              v-model="newSignal.takeProfit3"
              id="sum"
              type="number"
              placeholder="Take profit 3"
          />
        </b-form-group>
        <b-form-group>
          <label for="sum">Stop loss</label>
          <b-form-input
              v-model="newSignal.stopLoss"
              id="sum"
              type="number"
              placeholder="Take profit 3"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
        v-model="editSignalModal"
        id="modal-edit-signal"
        cancel-variant="outline-secondary"
        ok-title="Обновить"
        cancel-title="Закрыть"
        centered
        title="Редактирование сигнала"
        @ok="updateSignal"
    >
      <b-form>
        <b-form-group>
          <div class="position-signal">
            <span class="long" :class="{ active: activeLong }" @click="changePosition('long')">Продать</span>
            <span class="short" :class="{ active: activeShort }" @click="changePosition('short')">Купить</span>
          </div>
        </b-form-group>
        <b-form-group>
          <label>Статус</label>
          <v-select
              v-model="editSignal.status_id"
              label="name"
              key="id"
              :reduce="name => name.id"
              :options="signalStatusSelect"
          />
        </b-form-group>
        <b-form-group>
          <label>Пара</label>
          <v-select
              v-model="editSignal.currency_pair_id"
              label="symbol"
              key="id"
              :reduce="symbol => symbol.id"
              :options="currencyPairsSelect"
          />
        </b-form-group>
        <b-form-group>
          <label for="leverage">Плечо:</label>
          <b-form-input
              v-model="editSignal.adjust_leverage"
              id="leverage"
              type="number"
              placeholder="Плечо"
          />
        </b-form-group>
        <b-form-group>
          <label for="price">Покупка:</label>
          <b-form-input
              v-model="editSignal.start_from"
              id="price"
              type="number"
              placeholder="Покупка"
          />
        </b-form-group>
        <b-form-group>
          <label for="sum">Take profit</label>
          <b-form-input
              v-model="editSignal.take_profit"
              id="sum"
              type="number"
              placeholder="Take profit"
          />
        </b-form-group>
        <b-form-group>
          <label for="sum">Take profit 2</label>
          <b-form-input
              v-model="editSignal.take_profit_2"
              id="sum"
              type="number"
              placeholder="Take profit 2"
          />
        </b-form-group>
        <b-form-group>
          <label for="sum">Take profit 3</label>
          <b-form-input
              v-model="editSignal.take_profit_3"
              id="sum"
              type="number"
              placeholder="Take profit 3"
          />
        </b-form-group>
        <b-form-group>
          <label for="sum">Stop loss</label>
          <b-form-input
              v-model="editSignal.stop_loss"
              id="sum"
              type="number"
              placeholder="Take profit 3"
          />
        </b-form-group>

      </b-form>
    </b-modal>

    <b-modal
        v-model="deleteModal"
        id="modal-danger"
        ok-only
        ok-variant="danger"
        ok-title="Удалить"
        modal-class="modal-danger"
        centered
        title="Удаление сигнала"
        @ok="deleteSignal"
    >
      <b-card-text>
        Вы действительно хотите удалить сигнал?
      </b-card-text>
    </b-modal>

    <b-modal
        v-model="createOrderModal"
        id="modal-order"
        cancel-variant="outline-secondary"
        ok-title="Создать"
        cancel-title="Закрыть"
        centered
        title="Создание сделки"
        @ok="createNewOrder"
    >
      <!-- form -->
      <validation-observer
          ref="createOrderForm"
          #default="{invalid}"
      >
        <b-form>
          <b-form-group>
            <validation-provider
                #default="{ errors }"
                name="adjust_leverage"
                rules="required"
            >
              <label for="leverage">Плечо:</label>
              <b-form-input
                  v-model="newOrder.adjust_leverage"
                  id="leverage"
                  type="number"
                  placeholder="Плечо"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
                #default="{ errors }"
                name="price"
                rules="required"
            >
              <label for="price">Цена:</label>
              <b-form-input
                  v-model="newOrder.price"
                  id="price"
                  type="number"
                  placeholder="Цена"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
                #default="{ errors }"
                name="sum"
                rules="required"
            >
            <label for="sum">Сумма</label>
              <b-form-input
                  v-model="newOrder.sum"
                  id="sum"
                  type="number"
                  placeholder="Сумма"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Overlay -->
    <div class="body-content-overlay"/>

    <div class="action-head">
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click="createSignalModal = true"
          type="submit"
          variant="primary"
      >
        Создать
      </b-button>
    </div>

    <!-- Prodcuts -->
    <section class="list-view">
      <b-card
          v-for="product in items"
          :key="product.id"
          class="ecommerce-card"
          no-body
      >
        <div class="top-info">
          <div class="top-left">
            <img v-if="product.currency_pair_img" :src="getPathImage(product.currency_pair_img)" alt="pair">
            <img v-else src="/pair.png" alt="pair">
            <div class="pair">
              <p>{{ product.currency_pair }}</p>
              <span>{{ product.date }}</span>
            </div>
            <span :class="signalStatusClass(product.status_id)" class="status-signal">
              <img :src="signalStatusImage(product.status_id)" alt="status">
              <span class="status-text">{{ product.status_name }}</span>
            </span>
          </div>
          <div class="top-right">
            <b-button
                v-if="product.status_id === 1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="openCreateOrder(product)"
                type="submit"
                variant="primary"
                class="mr-1"
            >
              Войти в сделку
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="openEditSignal(product)"
                class="desktop-buttons"
            >
              Редактировать
            </b-button>
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="openRemoveModal(product.id)"
                class="btn-icon desktop-buttons"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>

            <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="dropdown-icon-wrapper mobile-buttons"
                right
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>
              <b-dropdown-item @click="openEditSignal(product)">
                Редактировать
              </b-dropdown-item>
              <b-dropdown-item @click="openRemoveModal(product.id)">
                Удалить
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="main-info">
          <div class="left-main">
            <div class="main-column">
              <div class="main-item">
                <span>Плечо</span>
                <p>{{ product.adjust_leverage }}x</p>
              </div>
              <div class="main-item">
                <span>{{ product.position === 'Long' ? 'Покупка' : 'Продажа' }}</span>
                <p>{{ product.position === 'Long' ? 'Long' : 'Short' }}</p>
              </div>
            </div>
            <div class="main-column">
              <div class="main-item">
                <span>Текущая цена</span>
<!--                <p>$ 15<span> .83</span></p>-->
                <p>${{ product.price }}</p>
              </div>
              <div class="row-item">
                <div class="main-item">
                  <span>Вход</span>
                  <p v-if="product.market_entry">По рынку</p>
                  <p v-else>$ {{ product.start_from }}</p>
                </div>
                <div class="main-item">
                  <span>Stop–loss</span>
                  <p>$ {{ product.stop_loss }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="right-main">
            <div class="takes">
              <div class="take-item">
                <span>Take 1</span>
                <p>$ {{ product.take_profit }}</p>
              </div>
              <div v-if="product.take_profit_2" class="take-item">
                <span>Take 2</span>
                <p>$ {{ product.take_profit_2 }}</p>
              </div>
              <div v-if="product.take_profit_3" class="take-item">
                <span>Take 2</span>
                <p>$ {{ product.take_profit_3 }}</p>
              </div>
            </div>
            <p>Объем за 24ч — <span>{{ product.hour_24 }}</span></p>
          </div>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
              v-model="filters.page"
              :total-rows="countItems"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
          :filters="filters"
          :filter-options="filterOptions"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BCardText,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { mapActions, mapState, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi } from './useECommerceShop'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BButton,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BCardText,
    BDropdown,
    BDropdownItem,

    // SFC
    ShopLeftFilterSidebar,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const {
      itemView, itemViewOptions,
    } = useShopUi()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  data() {
    return {
      required,
      deleteModal: false,
      createOrderModal: false,
      createSignalModal: false,
      editSignalModal: false,
      removeId: '',
      newOrder: {
        signal_id: '',
        price: '',
        adjust_leverage: '',
        market_entry: '',
        sum: '',
      },
      activeLong: true,
      activeShort: false,
      newSignal: {
        currencyPair: '',
        adjustLeverage: '',
        startFrom: '',
        takeProfit1: '',
        takeProfit2: '',
        takeProfit3: '',
        stopLoss: '',
      },
      editSignal: {
        id: '',
        currency_pair_id: '',
        adjust_leverage: '',
        start_from: '',
        take_profit: '',
        take_profit_2: '',
        take_profit_3: '',
        stop_loss: '',
        status_id: '',
      },
      connection: null,
    }
  },
  computed: {
    ...mapState({
      items: state => state.signal.signals,
      countItems: state => state.signal.countSignals,
      popupCreate: state => state.signal.popupCreate,
      popupEdit: state => state.signal.popupEdit,
      currencyPairsSelect: state => state.currencyPair.currencyPairsSelect,
      signalStatusSelect: state => state.signalStatus.signalStatusSelect,
    }),
    page() {
      return this.filters.page
    },
  },
  watch: {
    page() {
      this.getItems(this.page)
    },
  },
  mounted() {
    this.getItems()
    this.getCurrencyPairsSelect()
    this.getSignalStatusSelect()
  },
  methods: {
    ...mapMutations({
      setPricesFromWS: 'signal/setPricesFromWS',
    }),
    ...mapActions({
      createSignal: 'signal/createSignal',
      editedSignal: 'signal/editSignal',
      getSignals: 'signal/getSignals',
      deleteItem: 'signal/deleteSignal',
      createOrder: 'order/createOrder',
      getCurrencyPairsSelect: 'currencyPair/getCurrencyPairsSelect',
      getSignalStatusSelect: 'signalStatus/getSignalStatusSelect',
    }),
    getItems(page = 1) {
      this.getSignals(page)
        .then(() => {
          const tickersQuery = this.getAllTickers()
          this.connection = new WebSocket(`wss://fstream.binance.com/stream?streams=${tickersQuery}`)

          this.connection.onmessage = event => {
            this.setPricesFromWS(event.data)
          }
        })
    },
    getAllTickers() {
      const tickers = []

      this.items.forEach((value, index) => {
        if (!tickers.includes(value.currency_pair_split.toLowerCase() + '@miniTicker')) {
          tickers.push(value.currency_pair_split.toLowerCase() + '@miniTicker')
        }
      })

      return tickers.join('/')
    },
    getPathImage(path) {
      return path
      // return `${process.env.VITE_AXIOS_BASE_URL}/${path}`
    },
    signalStatusImage(status) {
      switch (status) {
        case 1:
          return '/signal-statuses/pending.svg'
        case 2:
          return '/signal-statuses/working.svg'
        case 3:
          return '/signal-statuses/success.svg'
        case 4:
          return '/signal-statuses/failed.svg'
        case 5:
          return '/signal-statuses/canceled.svg'
        case 6:
          return '/signal-statuses/breakeven.svg'
      }
    },
    signalStatusClass(status) {
      switch (status) {
        case 1:
          return 'status-pending'
        case 2:
          return 'status-working'
        case 3:
          return 'status-success'
        case 4:
          return 'status-failed'
        case 5:
          return 'status-canceled'
        case 6:
          return 'status-breakeven'
      }
    },
    openRemoveModal(id) {
      this.removeId = id
      this.deleteModal = true
    },
    deleteSignal() {
      // eslint-disable-next-line no-return-assign
      this.deleteItem(this.removeId).then(() => this.deleteModal = false)
    },
    openCreateOrder(item) {
      this.newOrder.signal_id = item.id
      this.newOrder.adjust_leverage = item.adjust_leverage

      let priceOrder = item.start_from
      if (item.market_entry) {
        priceOrder = 1
      }
      this.newOrder.price = priceOrder

      this.createOrderModal = true
    },
    openEditSignal(item) {
      if (item.position_id === 2) {
        this.changePosition('long')
      } else {
        this.changePosition('short')
      }
      this.editSignal = item
      this.editSignalModal = true
    },
    createNewSignal() {
      this.createSignal({
        currency_pair_id: this.newSignal.currencyPair.id,
        position_id: this.activeLong ? 2 : 1,
        start_from: this.newSignal.startFrom,
        take_profit: this.newSignal.takeProfit1,
        take_profit_2: this.newSignal.takeProfit2,
        take_profit_3: this.newSignal.takeProfit3,
        adjust_leverage: this.newSignal.adjustLeverage,
        stop_loss: this.newSignal.stopLoss,
      })
    },
    updateSignal() {
      this.editedSignal({
        id: this.editSignal.id,
        status_id: this.editSignal.status_id,
        currency_pair_id: this.editSignal.currency_pair_id,
        position_id: this.activeLong ? 2 : 1,
        start_from: this.editSignal.start_from,
        take_profit: this.editSignal.take_profit,
        take_profit_2: this.editSignal.take_profit_2,
        take_profit_3: this.editSignal.take_profit_3,
        adjust_leverage: this.editSignal.adjust_leverage,
        stop_loss: this.editSignal.stop_loss,
      })
    },
    createNewOrder(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      this.$refs.createOrderForm.validate().then(success => {
        if (success) {
          this.createOrder(this.newOrder).then(() => {
            this.createOrderModal = false

            this.$bvToast.toast('Сделка успешно создана', {
              title: 'Сделка',
              variant: 'success',
              solid: true,
            })
          })
            .catch((errors) => {
              let textError = ''

              if (errors.response.status === 422) {
                textError = 'Проверьте введенные данные'

                this.$refs.createOrderForm.setErrors(errors.response.data.errors)
              } else {
                textError = errors.response.data
              }

              this.$bvToast.toast(textError, {
                title: 'Ошибка',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
    changePosition(position) {
      if (position === 'long') {
        this.activeLong = true
        this.activeShort = false
      } else {
        this.activeShort = true
        this.activeLong = false
      }
    },
    beforeRouteLeave(to, from, next) {
      this.connection.close()
      next()
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.ecommerce-application .grid-view:not(.wishlist-items), [dir] .ecommerce-application .list-view:not(.wishlist-items) {
  margin-top: 15px;
}

.ecommerce-application .list-view .ecommerce-card {
  display: block;
}

.action-head {
  display: flex;
  justify-content: end;
}

.top-info {
  padding: 21px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(255 255 255 / 15%);
}

.top-left {
  display: flex;
  align-items: start;
}

.top-left img {
  max-width: 54px;
  margin-right: 16px;
}

.pair {
  margin-top: 7px;
  margin-right: 12px;
}

.pair p {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 4px;
}

.pair span,
.main-item span,
.take-item span {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-weight: 500;
}

.status-signal {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
}

.status-signal.status-pending {
  color: #5ECCE4;
  background: rgba(94, 204, 228, 0.15);
}

.status-signal.status-success {
  color: #61C478;
  background: rgba(97, 196, 120, 0.15);
}

.status-signal.status-failed {
  color: #DA5E5A;
  background: rgba(218, 94, 90, 0.15);
}

.status-signal.status-canceled {
  color: #97A4B0;
  background: rgba(151, 164, 176, 0.15);
}

.status-signal.status-working {
  color: #F2A356;
  background: rgba(242, 163, 86, 0.15);
}

.status-signal.status-breakeven {
  color: #7167E8;
  background: rgba(113, 103, 232, 0.15);
}

.status-signal img {
  margin-right: 4px;
}

.top-right > *:not(:last-child) {
  margin-right: 12px;
}

.left-main {
  display: flex;
  padding: 24px 24px 32px;
}

.left-main .main-column:first-child {
  margin-right: 127px;
}

.main-item > span {
  display: block;
  margin-bottom: 8px;
}

.main-column .main-item p {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.left-main > .main-column > .main-item:first-child p {
  color: #fff;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 28px;
}

.main-item p span {
  color: rgba(255, 255, 255, 0.4);
  font-size: 25px;
  margin-left: -5px;
}

.row-item {
  display: flex;
  min-width: 260px;
  justify-content: space-between;
}

.row-item .main-item:last-child p {
  color: rgba(255, 255, 255, 0.6);
}

.main-info {
  display: flex;
  justify-content: space-between;
}

.right-main {
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgb(255 255 255 / 15%);
  min-width: 290px;
}

.takes {
  flex: 1 0 auto;
}

.take-item {
  display: flex;
}

.takes .take-item:not(:last-child) {
  margin-bottom: 16px;
}

.take-item span {
  width: 50px;
  margin-right: 23px;
}

.take-item p {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.right-main > p {
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
}

.position-signal {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.position-signal span {
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  font-size: 1rem;
  line-height: 1rem;
  color: #c0c0c0;
  cursor: pointer;
}

.position-signal .long.active {
  background-color: #28c76f!important;
  border-color: #28c76f;
  -webkit-box-shadow: 0 4px 18px -4px rgb(40 199 111 / 65%);
  box-shadow: 0 4px 18px -4px rgb(40 199 111 / 65%);
  color: #fff;
}

.position-signal .short.active {
  background-color: #ea5455!important;
  border-color: #ea5455;
  -webkit-box-shadow: 0 4px 18px -4px rgb(234 84 85 / 65%);
  box-shadow: 0 4px 18px -4px rgb(234 84 85 / 65%);
  color: #fff;
}

.mobile-buttons {
  display: none;
}

.dropdown-icon-wrapper.mobile-buttons ::v-deep .btn.dropdown-toggle {
  padding: 10px;
}

@media all and (max-width: 1390px) {
  .mobile-buttons {
    display: inline-block;
  }
  .desktop-buttons {
    display: none;
  }

  .right-main[data-v-6047a77d] {
    min-width: initial;
  }

  .right-main > p {
    margin-top: 13px;
  }
}

@media all and (max-width: 770px) {
  .main-info {
    flex-direction: column;
  }
  .right-main {
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }
}

@media all and (max-width: 700px) {
  .status-text {
    display: none;
  }

  .status-signal img {
    margin-right: 0;
  }
}

@media all and (max-width: 660px) {
  .left-main {
    flex-direction: column;
    max-width: 280px;
  }

  .main-column {
    display: flex;
    justify-content: space-between;
  }

  .left-main .main-column:first-child {
    margin: 0;
    justify-content: space-between;
  }

  .left-main .main-column:last-child {
    flex-direction: column;
  }

  .left-main .main-column .row-item {
    justify-content: space-between;
    min-width: initial;
  }
}

@media all and (max-width: 590px) {
  .top-info {
    flex-direction: column;
  }

  .top-info .top-right {
    margin-top: 22px;
  }
}
</style>
